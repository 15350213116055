.career .career-info-item{
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 30px;
}

.career .career-info-item .icon{
    display: inline-block;
}

.career .career-info-item .icon .fa{
    font-size: 30px;
    color: var(--skin-color);  
}

@media (max-width: 991px) {
    .career .career-info-item .icon .fa{
        font-size: 26px;
    }
}

.career .career-info-item h4{
    font-size: 18px;
    font-weight: 700;
    color: var(--text-black-900);
    text-transform: capitalize;
    margin: 0px 0 5px;
}
@media (max-width: 991px) {
    .career .career-info-item h4{
        font-size: 16px;
    }
}

.career .career-title{
    color: var(--text-black-900);
    padding-bottom: 10px;
}

.career .career-form{
    flex: 0 0 100%;
    max-width: 100%;
}

.career .career-form .col-6{
    flex: 0 0 50%;
    max-width: 50%;
}
.career .career-form .col-12{
    flex: 0 0 100%;
    max-width: 100%;
}

.career .career-form .form-item{
    margin-bottom: 30px;
}

.career .career-form .form-item .form-control{
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    padding: 10px 25px;
    color: var(--text-black-700);
    transform: all 0.3s ease;
}

.career .career-form .form-item .form-control:focus{
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}

.career .career-form .form-item textarea.form-control{
    height: 140px;
}

.career .career-form .btn{
    height: 50px;
    padding: 0 50px;
}

.career .career-form .recaptcha{
    margin-bottom: 10px;
}

.career .loader {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    color: #f2f2f2;
    background: var(--skin-color);
    border-radius: 5px;
}

.career .btn:disabled {
    background: var(--bg-black-100);
    color: var(--text-black-900);
    cursor: not-allowed;
}

.upload-cv-title{
    color: var(--text-black-900);
    padding-left:15px;
    padding-bottom: 8px;
}