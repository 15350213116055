.carousel-item-inner {
  border: 6px solid var(--bg-black-100);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  height: 300px;
  width: 250px;
  align-items: center;
  text-align: center;
}

.carousel.unexpanded {
  height: 300px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.carousel.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.carousel img {
  transition: opacity 1s ease-in-out;
  position: absolute;
  opacity: 0;
}

.carousel.unexpanded img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  display: block;
  transition: opacity 1s ease-in-out;
}

.carousel.expanded img {
  transition: opacity 1s ease-in-out; 
  max-width: 90%;
  max-height: 90%;
}


.carousel img.active {
  opacity: 1;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--skin-color);
  color: white;
  border: none;
  padding: 2px 8px 4px 4px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 -6px 0 -6px;
}

.prev {
  left: 5px;
}

.next {
  right: 5px;
}

.cross {
  position: fixed;
  top: 10px;
  right: 60px;
  font-size: 32px;
  font-weight: bolder;
  cursor: pointer;
  color: var(--skin-color);
  z-index: 300;
}

.notice-download-btn {
  text-align: center;
  margin-top: 10px;
}

.download-button {
  background-color: var(--skin-color);
  color: #f2f2f2;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
}

.download-button:hover {
  background-color: var(--btn-hover);
}
