
.container{
    width: 100%;
    margin: auto;
}

.section {
  background-color: var(--bg-black-900);
  min-height: 100vh;
  display: flex;
  padding-top: 64px;
  padding-left: 64px;
  padding-right: 64px;
  opacity: 1;
}

.section-title{
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
}

.section-title h2{
    font-size: 40px;
    color:var(--text-black-900);
    font-weight: 700;
    position: relative;
}

@media (max-width: 991px) {
    .section-title h2{
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .section-title h2{
    font-size: 25px;
    }
    .section {
      padding-left: 24px;
      padding-right: 24px;
    }

}

.section-title h2::before{
    content: '';
    height: 4px;
    width: 50px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
}

.section-title h2::after{
    content: '';
    height: 4px;
    width: 25px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}

.hidden{
  display: none !important;
}

.padd-15
{
    padding-left: 15px;
    padding-right: 15px;
}
.row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}

.typing{
    color: var(--skin-color);

}

.btn{
    font-weight: 600;
    padding: 12px 35px;
    color: white;
    border-radius: 40px;
    display: inline-block;
    white-space:nowrap;
    border: none;
    background: var(--skin-color);
    transition: all 0.3s ease;
    cursor: pointer;
}

.btn:hover{
    background: var(--btn-hover);
    transform: scale(1.05);
}

.shadow-dark{
    box-shadow: 0 0 20px rgba(48,46, 77,0.15);
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--text-black-900);
  border-radius: 4px;
}

.copy-image-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.copy-image-container input{
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid var(--skin-color);
}

.copy-image-link{
    height: 30px;
    width: 100px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    color: var(--pure-white);
    background-color: var(--skin-color);
    border: none;
    border-radius: 5px;
    margin-left: 5px;
}

.copy-image-link:hover{
    background-color: var(--skin-color-3);
}

.error {
  color: red;
  margin-top: 5px;
}


.tooltip::before {
    content: attr(tooltip);
    position: absolute;
    top: -5px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: var(--bg-black-50);
    color: var(--text-black-900);
    font-size: 12px;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.tooltip:hover::before {
    opacity: 1;
}

.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.confirm-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.confirm-modal p {
  margin-bottom: 20px;
}

.confirm-modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.confirm-modal-buttons button {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-modal-buttons button:first-child {
  background-color: red;
  color: white;
}

.confirm-modal-buttons button:last-child {
  background-color: #e0e0e0;
}
