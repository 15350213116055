.slider {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider-slide.active {
  opacity: 1;
}

.slider-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}
