.management-section {
  background-color: var(--bg-black-900);
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 64px;
}

.shareholder-section{ 
    background-color: var(--bg-black-900);
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.management-card, .shareholder-card {
  background-color: var(--bg-black-100);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  overflow: hidden;
}

.management-image, .shareholder-image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 8px 8px 0 0px;
}

.management-name, .shareholder-name{
  color: var(--text-black-700);
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.management-title {
  color: var(--text-black-900);
  margin: 0 0 10px 0;
  font-size: 1em;
}

.shareholders-section-title{    
    background-color: var(--bg-black-900);
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 64px;
    padding-bottom: 32px;
    padding-left: 64px;
}

.shareholders-section-title h2{
    font-size: 40px;
    color:var(--text-black-900);
    font-weight: 700;
    position: relative;
}

@media (max-width: 991px) {
    .shareholders-section-title h2{
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .shareholders-section-title h2{
    font-size: 25px;
    }
}

.shareholders-section-title h2::before{
    content: '';
    height: 4px;
    width: 50px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
}

.shareholders-section-title h2::after{
    content: '';
    height: 4px;
    width: 25px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}


@media (max-width: 767px){
    .management-section{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .management-image, .shareholder-image {
        width: 180px;
        height: 180px;
    }
}