.about-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  border: 2px solid var(--skin-color);
}

.about-admin-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-admin-form {
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}
