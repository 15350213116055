
.gallery-section {
  background-color: var(--bg-black-900);
  display: flex;
  opacity: 1;
  padding-top: 64px;
  padding-left: 64px;
  padding-right: 64px;
}
.gallery-section-title{
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
}

.gallery-section-title h2{
    font-size: 40px;
    color:var(--text-black-900);
    font-weight: 700;
    position: relative;
}

@media (max-width: 991px) {
    .gallery-section-title h2{
        font-size: 30px;
    }
}
@media (max-width: 767px) {
    .gallery-section-title h2{
    font-size: 25px;
    }
    
.gallery-section {
  padding-left: 24px;
  padding-right: 24px;
}
}

.gallery-section-title h2::before{
    content: '';
    height: 4px;
    width: 50px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
}

.gallery-section-title h2::after{
    content: '';
    height: 4px;
    width: 25px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}

.gallery-row{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 32px;
}