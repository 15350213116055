@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&family=Poppins:wght@200;300;500;600&family=Roboto:wght@400;500;700;900&display=swap');


:root {
  --bg-black-900: #f2f2fc;
  --bg-black-100: #fdf9ff;
  --bg-black-50: #e8dfec;
  --text-black-900: #302e4d;
  --text-black-700: #504e70;
  --pure-white: #ffffff;
  --hover: var(--skin-color);
}

body.dark {
  --bg-black-900: #2f2f2f;
  --bg-black-100: #444343;
  --bg-black-50: #727272;
  --text-black-900: #ffffff;
  --text-black-700: #e9e9e9;
  --pure-white: #000000;
}

:root {
  --skin-color: #1d9200;
   --btn-hover: #176100;
}

:root {
  --skin-color-1: #ec1839;
  --skin-color-2: #ff5100;
  --skin-color-3: #1d9200;
  --skin-color-4: #003c9d;
  --skin-color-5: #8f0064;

  --btn-hover-1: #b50c25;
  --btn-hover-2: #963102;
  --btn-hover-3: #176100;
  --btn-hover-4: #01235a;
  --btn-hover-5: #510039;
}

body {
  line-height: 1.5;
  font-size: 16px;
  font-family: 'roboto';
}

@media (max-width: 991px) {
  body {
    font-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}

::before,
::after {
  box-sizing: border-box;
}